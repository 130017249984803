import React from "react";
import { Points } from "../../types/types";
import { PointsColumn } from "./PointsRow.styles";

const dp = 1;
const padding = 5;

interface Props{
    points: Points
}

const PointsRow: React.FC<Props> = ({ points }): JSX.Element => (
    <>
        <PointsColumn>
            {points.pointsOverWeeks[0] ? points.pointsOverWeeks[0].toFixed(dp).padStart(padding) : "   -  "}
        </PointsColumn>
        <PointsColumn>
            {points.pointsOverWeeks[1] !== 0.0 ? points.pointsOverWeeks[1].toFixed(dp).padStart(padding) : "   -  "}
        </PointsColumn>
        <PointsColumn>
            {points.pointsOverWeeks[2] !== 0.0 ? points.pointsOverWeeks[2].toFixed(dp).padStart(padding) : "   -  "}
        </PointsColumn>
        <PointsColumn>
            {points.pointsOverWeeks[3] !== 0.0 ? points.pointsOverWeeks[3].toFixed(dp).padStart(padding) : "   -  "}
        </PointsColumn>
        <PointsColumn>
            {points.totalPoints.toFixed(dp).padStart(padding)}
        </PointsColumn>
    </>
);

export default PointsRow;