import styled from 'styled-components';

export const StyledContainer = styled.div`
    background-color: #F0F8FF;
    border-radius: 8px;
    display: flex;
    padding: 4px 16px;
    justify-content: space-between;
    margin: 8px 0;
`;

export const PlayerPosition = styled.div`  
    display: flex;
    width: 32px;
    // padding: 0px 32px 0px 0px
`;

export const PlayerName = styled.div`
    display: flex;
    width: 160px;      
`;