import styled from "styled-components";

interface Props{
    primaryColour: string;
    secondaryColour: string;
    fontColour: string;
}

export const TeamIcon =  styled.div<Props>`
    padding: 24px;
    width: 40px;
    border-radius: 8px;
    border: 2px solid black;
    justify-content: center;
    font-weight: 600;
    vertical-align: middle;
    color: ${props => props.fontColour};
    background-image: -webkit-linear-gradient(130deg, ${props => props.secondaryColour} 25%, ${props => props.primaryColour} 25%);
    position: relative;
`


export const TeamCountContainer = styled.div`
    border-radius: 50%;
    background: white;
    border: 1px solid black;
    color: black;
    padding: 10px;
    width: 24px;
    height: 24px;
    position: absolute;
    right: -20px;
    top: -20px;
`;

