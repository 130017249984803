import styled from "styled-components";

export const MatchupContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px 24px;
    gap: 32px;
`;

export const RealSection = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
`