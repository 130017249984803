import React from 'react'
import './App.css';
import { useEffect, useState } from 'react';
import Matchup from '../Matchup/component';

var countDownDate = new Date("Jan 15, 2022 21:35:00").getTime();

const App = (): JSX.Element => {
   return (
    <main>
      <h1>ELG Playoffs</h1>
      <Matchup/>
    </main>
  );

    
}

export default App;
