import styled from 'styled-components';

export const StyledHeader = styled.h2`
    color: white;
`

export const StyledContainer = styled.div`
    background-color: #4682B4;
    border-radius: 8px;
    border: 2px solid grey;
    display: flex;
    flex-direction: column;
    padding: 0px 16px 8px;
    margin: 80px 0;
    position: relative;
`;

export const TotalPointsContainer = styled.div`
    font-weight: bold;
    background-color: #708090;
    color: white;
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
`

const TrophyContainer = styled.div`
    border-radius: 50%;
    background: white;
    border: 1px solid black;
    color: white;
    padding: 10px;
    width: 48px;
    height: 48px;
    position: absolute;
    right: -20px;
    top: -20px;
`;

interface Props {
    rank: number;
}

const placement = ['#ffd700', '#c0c0c0', '#cd7f32 ']

export const TrophyIcon = ({rank}: Props): JSX.Element => { 

    return (
    <TrophyContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 402.384 402.384">
            <path
                fill="none"
                d="M26.432 58.143c4.973 22.817 18.264 69.66 48.445 103.181h21.799C79.397 125.92 72.367 86.085 69.52 58.143H26.432zM163.532 332.443c-5.463 1.495-29.824 9.527-37.311 35.366h149.941c-7.47-25.78-31.742-33.835-37.32-35.366h-75.31zM172.093 308.443h58.198c9.925 0 18-8.075 18-18v-1.329c0-9.926-8.075-18-18-18h-58.198c-9.925 0-18 8.074-18 18v1.329c0 9.925 8.075 18 18 18zM305.706 161.324h21.801c30.273-33.61 43.521-80.389 48.463-103.181h-43.105c-2.849 27.942-9.878 67.777-27.159 103.181z"
            />
            <path
                fill={placement[rank - 1]}
                d="M91.911 34.575c1.492 31.837 10.132 119.62 59.703 160.604a12.009 12.009 0 0 1 4.114 6.864l9.138 45.071h72.65l9.139-45.071a12.009 12.009 0 0 1 4.114-6.864c49.571-40.983 58.212-128.764 59.702-160.604H91.911z"
            />
            <path
                fill="#3d6889"
                d="M399.405 38.229a12.002 12.002 0 0 0-9.022-4.086h-55.815c.255-6.641.22-10.847.207-11.737-.093-6.561-5.437-11.831-11.999-11.831H79.607c-6.562 0-11.907 5.271-11.999 11.831-.013.891-.048 5.097.207 11.737H12A12.002 12.002 0 0 0 .102 47.706c.439 3.354 11.355 82.726 60.94 133.963a12 12 0 0 0 8.623 3.655h40.986c6.45 9.32 13.849 17.969 22.374 25.529l9.773 48.203c-7.83 7.634-12.707 18.284-12.707 30.058v1.329c0 9.935 3.475 19.069 9.264 26.268a84.973 84.973 0 0 0-8.223 5.188c-13.217 9.454-29.319 27.061-30.974 57.255a11.994 11.994 0 0 0 3.268 8.906 12.005 12.005 0 0 0 8.714 3.75h178.101a12.003 12.003 0 0 0 11.982-12.656c-1.654-30.194-17.756-47.801-30.973-57.255a84.973 84.973 0 0 0-8.223-5.188c5.788-7.198 9.263-16.333 9.263-26.268v-1.329c0-11.773-4.876-22.425-12.707-30.058l9.773-48.203c8.526-7.561 15.924-16.209 22.374-25.529h40.986c3.252 0 6.363-1.319 8.623-3.655C390.925 130.432 401.84 51.06 402.28 47.706a11.999 11.999 0 0 0-2.875-9.477zM154.093 289.114c0-9.926 8.075-18 18-18h58.198c9.925 0 18 8.074 18 18v1.329c0 9.925-8.075 18-18 18h-58.198c-9.925 0-18-8.075-18-18v-1.329zm96.676-93.936a12.009 12.009 0 0 0-4.114 6.864l-9.139 45.071h-72.65l-9.138-45.071a12.009 12.009 0 0 0-4.114-6.864C102.043 154.195 93.403 66.412 91.911 34.575h218.561c-1.491 31.84-10.131 119.62-59.703 160.603zM74.878 161.324C44.696 127.803 31.405 80.961 26.432 58.143H69.52c2.847 27.941 9.877 67.776 27.156 103.181H74.878zm201.285 206.485H126.221c7.486-25.839 31.848-33.871 37.311-35.366h75.311c5.578 1.531 29.85 9.586 37.32 35.366zm51.344-206.485h-21.801c17.281-35.404 24.31-75.239 27.158-103.181h43.105c-4.942 22.792-18.189 69.571-48.462 103.181z"
            />
        </svg>
    </TrophyContainer>
)};