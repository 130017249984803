import React from "react";
import { RealTeam } from "../../types/types";
import { TeamIcon, TeamCountContainer } from "./TeamBanner";

interface Props {
    realTeam: RealTeam
}

const TeamIconHolder: React.FC<Props> = ({realTeam}): JSX.Element => (
    <TeamIcon 
        primaryColour={realTeam.eliminated ?  "#979c9f" : realTeam.primaryColour} 
        secondaryColour={realTeam.eliminated ?  "#95a5a6" : realTeam.secondaryColour} 
        fontColour={realTeam.fontColour}>
        {realTeam.shortCode}
        {realTeam.numberOfPlayers > 0 && 

        <TeamCountContainer>
            {realTeam.numberOfPlayers}
        </TeamCountContainer>
        }
    </TeamIcon>         
)

export default TeamIconHolder;