import axios from 'axios';
import { JsonResponse, Team } from '../types/types';

const apiKey = "016f07d886d243ffa588193f0424473b"

async function fetchPlayerStats(): Promise<JsonResponse> {
    const url = `/api/controller`;
    const response = await axios.get<JsonResponse>(url);
    return response.data;
}

export default fetchPlayerStats;
