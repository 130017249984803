import styled from 'styled-components';

export const StyledContainer = styled.div`
    background-color: #708090;
    border-radius: 8px;
    color: white;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    padding: 4px 16px;
    margin: 8px auto;
`;

export const PositionHeader = styled.div`  
    display: flex;
    font-weight: bold;    
    width: 32px;
`;

export const PlayerHeader = styled.div`
    display: flex;
    font-weight: bold;
    width: 160px;      
`;

export const PointsHeader = styled.div`
    display: flex;
    font-weight: bold;
    width: 40px;
    text-align: right;
`

const HeaderRow: React.FC = (): JSX.Element => (
    <StyledContainer>
        <PositionHeader>Pos</PositionHeader>
        <PlayerHeader>Player Name</PlayerHeader>
        <PointsHeader>WC</PointsHeader> 
        <PointsHeader>QF</PointsHeader> 
        <PointsHeader>SF</PointsHeader> 
        <PointsHeader>SB</PointsHeader> 
        <PointsHeader>Σ</PointsHeader> 
    </StyledContainer>
)


export default HeaderRow;