import { Player } from "../../types/types";
import PointsRow from "../PointsRow/component";
import { PlayerPosition, PlayerName, StyledContainer } from "./PlayerRow.styles";

interface Props {
    player: Player
}

const PlayerRow: React.FC<Props> = ({ player }): JSX.Element => {
    return (
        <StyledContainer>
            <PlayerPosition> 
                {player.playerPosition}
            </PlayerPosition>
            <PlayerName>
                {player.playerName}
            </PlayerName>
            <PointsRow points={player.playerPoints}/>
        </StyledContainer>
    )
}


export default PlayerRow;