import React, { useEffect, useState } from "react";
import fetchPlayerStats from "../../data/FetchPlayerData";
import { RealTeam, Team } from "../../types/types";
import TeamIconHolder from "../TeamBanner/component";
import TeamSheet from "../TeamSheet/component";
import { MatchupContainer, RealSection } from "./Matchup.styles";

interface Props {
    fantasyTeams: Team[]
}

interface RealProps {
    realTeams: RealTeam[]
}

const TeamSheets = ({fantasyTeams: managers}:Props): JSX.Element => (
    <>
        {managers.map( (manager: Team) => {
            return <TeamSheet key={`${manager.manager}-sheet`} playerTeamSheet={manager.players} teamName={manager.manager} rank={manager.rank} totalPoints={manager.totalPoints} />
        })} 
    </> 
);


const TeamIcons = ({realTeams: teams}:RealProps): JSX.Element => (
    <>  
        {teams.map( (team: RealTeam) => {
           return <TeamIconHolder key={team.shortCode} realTeam={team}/>        
        })}
    </>
);

const Matchup: React.FC = (): JSX.Element => { 
    const [managers, setManagers] = useState<Team[]>([]);
    const [realTeams, setRealTeams] = useState<RealTeam[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
        (async() => {
            const res = await fetchPlayerStats();
            setManagers(res.teams);
            setRealTeams(res.realTeams)
            setLoading(false);
        })();
    }, []);

    return (
        <>
            <RealSection>
                {loading ? <div>loading</div> : <TeamIcons realTeams={realTeams}/> }
            </RealSection>
            <MatchupContainer>
                {loading ? <div>loading</div> : <TeamSheets fantasyTeams={managers}/> }
            </MatchupContainer>    
        </>
    )
}

export default Matchup;