import { Player } from "../../types/types";
import PlayerRow from "../PlayerRow/component"
import HeaderRow from "../HeaderRow/component"
import { StyledContainer, StyledHeader, TrophyIcon, TotalPointsContainer } from "./TeamSheet.styles"

interface Props {
    playerTeamSheet: Player[];
    teamName: String;
    rank: number;
    totalPoints: Number;
}
 
const TeamSheet: React.FC<Props> = ({ playerTeamSheet, teamName, rank, totalPoints }): JSX.Element => (        
    <StyledContainer>
        {rank < 4 && 
            <TrophyIcon rank={rank}/>
        }
        <StyledHeader>{teamName}</StyledHeader>
        <HeaderRow />
        {playerTeamSheet.map((player: Player, index: number) => <PlayerRow key={index} player={player}/>)}
        <hr style={{width: '100%' }} />
        <TotalPointsContainer>{totalPoints.toFixed(2)}</TotalPointsContainer>
    </StyledContainer>
);

export default TeamSheet;